%organism-wi-lieux{
    background-color: $core-color;
    .seu-media-picto{
        background-color: #FFFFFF;
        background-image: url( svg-bg-uri($svg-marker, $core-color) );
        background-size: 20px;
    }
    .seu-media-text{
        .seu-media-title{
            color: #FFFFFF;
            margin-bottom: 20px;
        }
        a{
            color: #FFFFFF;
            text-decoration: none;
        }
        & > *:last-child{
            margin-bottom: 0;
        }
    }

    // Liste de lieux
    .seu-wi-lieux-list{
        display: none;
        &.seu-visible{
            display: flex;
            @include RWD(mobile){
                display: block;
            }
        }
        flex-wrap: wrap;
        align-items: center;

        .seu-wi-lieux-item{
            width: 50%;
            margin-bottom: 20px !important;
            @include RWD(mobile){
                width: 100%;
            }
            display: flex;
            align-items: center;
            &:nth-child(2n){
                padding-left: 20px;
                @include RWD(mobile){
                    padding-left: 0;
                }
            }
            &:nth-child(2n+1){
                padding-right: 20px;
                @include RWD(mobile){
                    padding-right: 0;
                }
            }
            &:last-child{
                @include RWD(mobile){
                    margin-bottom: 0;
                }
            }
            &:before{
                margin-right: 20px;
                content: '';
                display: inline-block;
                width: 20px;
                height: 25px;
                background-size: 15px;
                background-position: center;
                background-repeat: no-repeat;
                background-image: url( svg-bg-uri($svg-marker, #FFFFFF) );
                flex-shrink: 0;
            }
        }
    }
    .seu-pagination{
        @include RWD(desktop){
            padding: 0 180px;            
        }

        .seu-is-active{
            .seu-btn-text{
                color: $third-color !important;
            }
        }
        .seu-pagin-item:not(.seu-pagin-next):not(.seu-pagin-prev):not(.seu-pagin-first):not(.seu-pagin-last) .seu-btn-text{
            padding: 10px;
            color: #FFFFFF;
        }
        .seu-disabled{
            opacity: 0 !important;
        }
        .seu-pagin-prev{
            .seu-btn-text{
                order: 2;
                margin-left: 20px !important;
                margin-right: 0 !important;
            }
            .seu-btn-arrow{
                transform: rotate(-180deg);
                order: 1;
            }
        }
    }
}