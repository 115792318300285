.page-navigation404{
    #main-content{
        @extend %organism-rte;

        form{
            @extend %molecule-form-components;
            @include RWD(mobile){
                padding: 0;
                margin-bottom: 40px;
            }
            & > div{
                display: flex;

                @include RWD(mobile){
                    flex-direction: column;
                    align-items: flex-end;
                }
            }
            .form-group{
                flex: 1;
                margin-bottom: 20px;
                @include RWD(mobile){
                    width: 100%;
                }

                input{
                    height: 45px !important;
                }
            }
            button{
                height: 45px !important;
                margin-left: 20px;
            }
        }
    }
}