%organism-home-une{
    position: relative;
    overflow: hidden;
    background-color: #f6f6f6;
    .seu-top-line{
        // transform: translateY(-75px);
    }
    .seu-une-top-background{
        background: #F6F6F6;
        position: absolute;
        top: 0;
        width: 101%;
        content: '';
        display: block;
        height: 500px;
        z-index: 2;

    }
    .seu-une-background{
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        bottom: -1px; // Fix firefox mac
        left: 50%;
        transform: translateX(-50%);
        width: 101%;
        content: '';
        display: block;
        height: 900px;
        z-index: 1;
        @include RWD(mobile){
            height: 450px;
        }
    }
    .seu-section-title{
        z-index: 2;
        position: relative;
    }
    .seu-btn-line{
        position: relative;
        z-index: 2;
    }
}