.seu-wi-link-group,
.seu-wi-download{
    display: block;
    @extend %molecule-wi-mediabox; 
    @extend %organism-wi-link-group;
    margin-bottom: $margin-item-rte;

    & + .seu-wi-link-group,
    & + .seu-wi-download{
        margin-top: -$margin-item-rte;
        border-top: solid 1px #f6f6f6;
    }
}

.seu-wi-link-group{ 
    text-decoration: none !important;
    .seu-media-picto{
        background-size: 30px;
        background-image: url( svg-bg-uri($svg-link-group, $core-color) );
        @include RWD(mobile){
            background-size: 15px;
        }
    } 
}
.seu-wi-download{ 
    .seu-media-picto{
        background-size: 25px;
        background-image: url( svg-bg-uri($svg-download, $core-color) );
        @include RWD(mobile){
            background-size: 15px;
        }
    }
}