%molecule-headings-rte{
    h2{
        @extend %atom-h2-rte;
        // clear: both;
        margin-bottom: $margin-h2;

        @include RWD(mobile){
            margin-bottom: $margin-h2-mobile;            
        }
    }
    h3{
        @extend %atom-h3-rte; 
        // clear: both;
        margin-bottom: $margin-h3;

        @include RWD(mobile){
            margin-bottom: $margin-h3-mobile;            
        }
    }
    h4{
        @extend %atom-h4-rte;
        // clear: both;
        margin-bottom: $margin-h4;

        @include RWD(mobile){
            margin-bottom: $margin-h4-mobile;            
        }
    } 
    h5{
        @extend %atom-h5-rte;
        // clear: both;
        margin-bottom: $margin-h5;

        @include RWD(mobile){
            margin-bottom: $margin-h5-mobile;            
        }
    }
}