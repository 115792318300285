%molecule-wi-section{
    padding-top: 60px;
    padding-bottom: 100px;
    @include RWD(mobile){
        padding-top: 30px;
        padding-bottom: 50px;
    }
    // Titre de sections
    .seu-section-title{
        margin-bottom: 50px;
        @include RWD(mobile){
            margin-bottom: 20px;
        }
         .seu-suptitle{
            display: block;
            font-weight: 400;
            font-family: $montserrat;
            font-size: 3.0rem;
            text-transform: uppercase; 
            line-height: 0.7;  
            margin-bottom: 5px;
            @include RWD(mobile){
                font-size: 1.8rem;
            }
         } 
         .seu-title{
            font-family: $montserrat;
            font-weight: 700;
            font-size: 3.7rem;
            text-transform: uppercase;
            line-height: 0.8;
            display: block;

            @include RWD(mobile){
                font-size: 2.3rem;
            }
         }

         &:before{ 
             content: '';
             display: block;
             height: 15px;
             background-size: 50px; 
             background-repeat: no-repeat; 
             margin-bottom: 25px;

             @include RWD(mobile){
                 margin-bottom: 10px;
             }
         }
    }
    // Texte
    .seu-wi-text{
        .seu-wi-title{
            font-family: $site-font;
            font-weight: 700;
            text-transform: uppercase;
            font-size: 1.7rem;
            line-height: 1.58;

            @include RWD(mobile){
                font-size: 1.4rem;
                margin-bottom: 10px;
            }
        }
        p{
            font-family: $main-font;
            font-size: 1.5rem;
            font-weight: 400;
            line-height: 1.8;
            margin-bottom: 20px;
            @include RWD(mobile){
                font-size: 1.2rem;
            }
        }
    }
    // Ligne bouton en savoir plus
    .seu-btn-line{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        a + a {
            margin-left: 55px;
        }

        @include RWD(mobile){
            flex-direction: column;
            align-items: center;
            a + a {
                margin-top: 20px;
                margin-left: 0;
            }
        }
    }
}