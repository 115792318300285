%molecule-home-section{
    padding-bottom: 80px;
    padding-top: 100px;
    @include RWD(mobile){
        padding-top: 40px;
        padding-bottom: 50px;
    }
    // Titre de sections
    .seu-section-title{
        margin-bottom: 50px;
        @include RWD(mobile){
            margin-bottom: 20px;
        }
         .seu-suptitle{
             display: block;
            font-weight: 400;
            font-family: $montserrat;
            font-size: 3.0rem;
            text-transform: uppercase; 
            text-align: center;
            line-height: 0.7;  
            margin-bottom: 5px;
            @include RWD(mobile){
                font-size: 1.8rem;
            }
         } 
         .seu-title{
            text-align: center;
            font-family: $montserrat;
            font-weight: 700;
            font-size: 5.0rem;
            text-transform: uppercase;
            line-height: 0.8;
            color: $core-color;
            display: block;

            @include RWD(mobile){
                font-size: 3.0rem;
            }
         }

         &:before{ 
             content: '';
             display: block;
             height: 15px;
             background-size: 50px; 
             background-position: center;
             background-repeat: no-repeat; 
             background-image: url( svg-bg-uri($svg-wave, #dddddd) ); 
             margin-bottom: 25px;

             @include RWD(mobile){
                 margin-bottom: 10px;
             }
         }
    }
    // Ligne bouton en savoir plus
    .seu-btn-line{
        text-align: center;
    }
}