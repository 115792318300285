%organism-header{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    z-index: 200;
    transform: translate3d(0,0,0);
    transition: all 0.3s;
    @include RWD(tablet){
        position: fixed;
    }
    &.seu-mmenu{
        height: 100%;
    }
    .seu-scrolled-search-engine{
        @extend %molecule-header-search;
    }
    // Nav top
    .seu-nav-top{
        height: 45px;
        background: #FFFFFF;
        z-index: 2;
        width: 100%;
        position: relative;
        transition: all 0.3s;
        .seu-container{
            display: flex;
            align-items: center;
            height: 100%;

            @include RWD(tablet){
                padding: 0;
            }
        }
        .seu-menu-triggerBox{
            @extend %molecule-menu-rwd;
        }
        .seu-scrolled-logo{
            display: none;
            height: auto;
            width: 195px;
            @include RWD(tablet){
                margin-left: 10px;
                width: 160px;
            }
            @include RWD(mobile){
                margin-left: 0;
                display: block;
                width: 150px;
                height: auto;
            }
        }
        .seu-fill{
            flex: 1;
        }
        .seu-nav-btn{
            height: 100%;
            padding: 0 25px;
            font-size: 1.7rem;
            @include RWD(mobile){
                padding: 0 10px;
            }

            .seu-flexbox{
                display: flex;
                align-items: center;
                height: 100%;
            }
            .seu-text{
                line-height: 1;
                color: #505050;
                text-transform: uppercase;
                font-family: $montserrat;
                font-weight: 700;
                font-size: 1.7rem;
                @include RWD(mobile){
                    display: none;
                }
                position: relative;
                &:after{
                    @extend %animation-hoverBar-in;
                    background-color: #505050;
                }
            }
            .seu-picto{
                content: '';
                display: inline-block;
                width: 20px;
                height: 20px;
                background-size: 20px;
                background-position: center;
                background-repeat: no-repeat;
                margin-right: 10px;
                @include RWD(mobile){
                    margin: 0;
                } 
            }
            &:hover, &:focus{
                text-decoration: none;
                .seu-text{
                    &:after{
                        @extend %animation-hoverBar-out;
                    }
                }
            }

            &.seu-nav-account{
                .seu-picto{
                    background-image: url( svg-bg-uri($svg-account, #505050) );                    
                }
            }
            &.seu-nav-contact{
                .seu-picto{
                    background-image: url( svg-bg-uri($svg-mail, #505050) );
                }
            }
        }
        #seu-search-trigger{
            display: none;
            background-color: $core-color;
            content: '';
            width: 55px;
            height: 100%;
            background-size: 15px;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url( svg-bg-uri($svg-loupe, #FFFFFF) );
            outline: none !important;

            @include RWD(tablet){
                display: block;
            }
        }
        .seu-lang-list{
            display: flex;
            height: 100%;

            @include RWD(tablet){
                display: block;
            }
            &:hover{
                @include RWD(desktop){
                    .seu-lang{
                        display: flex;
                    }                    
                }
            }
            &.seu-open{
                @include RWD(tablet){
                    .seu-lang{
                        display: flex;
                    }
                }
            }
        }
        .seu-lang{
            width: 55px;
            height: 100%;
            font-family: $montserrat;
            font-size: 1.4rem;
            font-weight: 400;
            color: #7e7e7e;
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: uppercase;
            background-color: #FFFFFF;
            transition: all, 0.5s;
            @include RWD(tablet){
                display: none;
            }
            &.actif{
                background-color: $third-color;
                pointer-events: none;
                color: $core-color;
                display: flex;
            }
            &:hover, &:focus{
                &:not(.actif){
                    background-color: #7e7e7e;
                    color: #FFFFFF;
                    text-decoration: none;
                }
            }
        }
    }
    // Nav bottom
    .seu-nav-bottom{
        height: 60px;
        background: $core-color; 
        z-index: 1;
        position: absolute;
        top: calc(100vh - 60px);
        // transform: translateY(-100%);
        transition: transform 0s;
        width: 100%; 
        // border-bottom: solid 1px #dcdcdc;
        box-shadow: 3px 4px 5px 0px rgba(0, 0, 0, 0.05);
        @include RWD(tablet){
            display: none;
        }

        .seu-container{
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 100%;
            @include RWD(tablet){
                padding: 20px;
            }
            @extend %molecule-main-menu;
        }
    }

    // Etats scrolled
    &.scrolled{
        .seu-nav-top{
            transform: translateY(-100%);   
            @include RWD(tablet){
                transform: none !important;
                position: fixed;
            }
        }
    }
    &.scrolled-hp{
        position: fixed;
        .seu-nav-top{
            transform: translateY(0);
            .seu-scrolled-logo{
                display: block;
            }
            #seu-search-trigger{
                display: block;
            }
        }
        .seu-nav-bottom{
            transition: transform 0.3s;
            position: relative;
            top: -45px !important;
            transform: translateY(45px);
        }
    }
} 