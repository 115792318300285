
@keyframes bounceInUp {
    from, 60%, 75%, 90%, to {
        animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    }

    from {
        opacity: 0;
        transform: translate3d(0, 100px, 0);
    }

    50% {
        opacity: 0.8;
        transform: translate3d(0, -10px, 0);
    }

    80% {
        opacity: 1;
        transform: translate3d(0, 3px, 0);
    }

    90% {
        opacity: 1;
        transform: translate3d(0, -1.5px, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}