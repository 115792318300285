%text-h1{
    font-size: 50px;
    font-size: 5.0rem;
    font-weight: 700;
    text-transform: uppercase;
    color: $core-color;
    font-family: $site-font;
    line-height: 1.2;
    
    @include RWD(mobile){
        font-size: 2.5rem;
    }
}

%text-h2{
    font-family: $site-font;
    color: #505050;
    font-size: 3.7rem; 
    font-weight: 500;
    text-transform: uppercase;
    line-height: 1.2;
    word-break: break-word;
    hyphens: auto;

    @include RWD(mobile){
        font-size: 2.5rem;
    }
}
%text-h3{
    font-family: $site-font;    
    font-weight: 400;
    font-size: 3.0rem;
    color: $core-color;
    line-height: 1.2;
    text-transform: uppercase;
    
    @include RWD(mobile){
        font-size: 1.7rem;
    }
}
%text-h4{
    font-family: $site-font;    
    font-weight: 700;
    font-size: 1.7rem;
    color: $core-color;
    line-height: 1.2;
    text-transform: uppercase;

    @include RWD(mobile){
        font-size: 1.4rem;
    }
}
%text-h5{
    font-size: 14px;
    text-transform: uppercase;
}
%text-hat{
    font-family: $site-font;
    font-weight: 400;
    font-size: 3.0rem;
    line-height: 1.2;
    color: $core-color;

    @include RWD(mobile){
        font-size: 2rem;
    }
}

//  HP
%text-section-title-hp{
    font-weight: 700;
    font-size: 32px;
    font-size: 3.2rem;
    letter-spacing: 0.05em;
}
%text-section-subtitle-hp{
    font-weight: 400;
    font-size: 16px;
    font-size: 1.6rem;
    text-transform: uppercase; 
    letter-spacing: 0.05em;
}

// TEASERS
%text-teaser-title{
    font-weight: 500;
    font-size: 20px;
    font-size: 2.0rem;
}
