%wi-centered-text{
    .seu-section-title .seu-title,
    .seu-wi-content{
        text-align: center;
    }   
    .seu-section-title:before{
        background-position: center;
    }
    &--mobile{
        @include RWD(mobile){
            .seu-section-title .seu-title,
            .seu-wi-content{
                text-align: center;
            }   
            .seu-section-title:before{
                background-position: center;
            }     
        }
    }
}
%wi-lefted-text{
    .seu-section-title .seu-title,
    .seu-wi-content{
        text-align: left;
    }   
    .seu-section-title:before{
        background-position: left center;
    }
    @include RWD(mobile){
        .seu-section-title .seu-title,
        .seu-wi-content{
            text-align: center;
        }   
        .seu-section-title:before{
            background-position: center;
        }     
    }
}