%molecule-images-rte{
    figure{
        @extend %atom-image-general;
    }
    &.image-xs figure{
        width: $image-xs;
    }
    &.image-sm figure{
        width: $image-sm;
    }
    &.image-md figure{
        width: $image-md;
    }
    &.image-original figure{
        @extend %atom-image-original;
    }
    &.image-full figure{
        @extend %atom-image-full;
    }
    &.image-left figure{
        float: left;
        margin-right: 40px;
    }
    &.image-right figure{
        float: right;
        margin-left: 40px;
    }
}