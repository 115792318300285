#seu-une{
    // Filtres
    .seu-filters{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 40px;
        z-index: 2;
        position: relative;

        .seu-actu-filter{
            margin: 0 5px;
            &:not(.seu-actif){
                @extend %atom-btn-round--grey;
            }
            &.seu-actif{
                @extend %atom-btn-round--inverted; 
                pointer-events: none;
            }
            @include RWD(mobile){
                padding: 10px !important;
                .seu-btn-text{
                    font-size: 1.2rem !important;
                }
            }
        }
    }

    // Slider
    .seu-slider-une-container{
        @extend %organism-slider-une;
    }

    // Templates
    #seu-une-templates{
        display: none;
    }
}