#seu-socials{
    .seu-filters{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 40px;

        .seu-social-filter{
            margin: 0 5px;
            padding: 0px !important;
            height: 40px; width: 40px;
            
            // Bouton texte
            &.seu-texted{
                @extend %atom-btn-round--grey;
                .seu-btn-text{
                    font-size: 1.0rem;
                    font-weight: 800;
                    color: $core-color;
                }
                &.seu-actif{
                    background-color: $second-color;
                    border-color: $second-color;
                    pointer-events: none;
                    svg{
                        fill: $core-color; 
                    }          
                } 
                &:hover, &:focus{
                    background-color: $second-color !important;
                    .seu-btn-text{
                        color: $core-color !important;
                    }
                }   
            }
            // Boutons Picto
            &:not(.seu-texted){
                border-radius: 10px;
                transition: all, 0.5s;
                border-style: solid;
                border-width: 1px;
                border-color: transparent;
                background-color: #ffffff;
                border-color: #ffffff;
                
                svg{
                    height: 17px;
                    width: 17px;
                    fill: $core-color;
                    transition: all, 0.5s;
                }
                &:hover, &:focus{
                    background-color: $second-color;
                }
                &.seu-actif{
                    background-color: $second-color;
                    border-color: $second-color;
                    pointer-events: none;
                    svg{
                        fill: $core-color; 
                    }              
                }

            }
        }
    }
    // Slider
    .seu-slider-social-container{
        @extend %organism-slider-social;
    }

    // Templates
    #seu-social-templates{
        display: none; 
    } 
}