%atom-btn-int{ 
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0px 28px;
    height: 50px;
    text-decoration: none;
    position: relative;
    cursor: pointer;

    .btn-text{
        position: relative;
        color: #FFFFFF;
        font-weight: 500;
        font-size: 16px;
        font-size: 1.6rem;
    }
    .flexbox{
        width: 100%;
        z-index: 1;
    }
    &:before{
        position: absolute;
        bottom: 0;
        left: 0;
        height: 0;
        width: 100%;
        content: '';
        display: block;
        transition: all, 0.3s;
    }
    &:hover, &:focus{
        &:before{
            height: 100%;
        }
    }

    &--inverted{
        @extend %atom-btn-int;
        background-color: $second-color;
        &:before{
            background-color: $core-color;
        }
    }
    &--core{
        @extend %atom-btn-int;
        background-color: $core-color;
        &:before{
            background-color: $second-color;
        }
    }
    &--white{
        @extend %atom-btn-int;
        background-color: #FFFFFF;
        .btn-text{
            color: #222222;
        }
        &:before{
            background-color: $second-color;
        }
        &:hover, &:focus{
            .btn-text{
                color: #FFFFFF;
            }
        }
    }
}