// .ios, .ipad, .iphone, .ipod

// If input/select/textarea inside Fixed element /!\ conflict with edge: prevent page scrolling
// html, body{
// 	// -webkit-overflow-scrolling : touch !important;
//     overflow: auto !important;
//     // height: 100% !important;
// }

.seu.ios #seu-banner{
    background-attachment: scroll;
}
.seu.ios{
    header .seu-nav-top .seu-menu-triggerBox .seu-menu-panel .seu-menu-rwd-content{
        height: calc(100% - 100px);        
    }
}