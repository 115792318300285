%molecule-wi-mediabox{
    padding: 30px 50px 40px 50px;
    @include RWD(tablet){
        padding: 30px;
    }
    @include RWD(mobile){
        padding: 15px;
    }
    .seu-media-container{
        display: flex;
        flex-wrap: wrap;
    }
    .seu-media-left{
        margin-right: 20px;
        @include RWD(mobile){
            width: 100%;
            margin-right: 0;
        }
    }
    .seu-media-picto{
        content: '';
        display: inline-block;
        width: 70px;
        height: 70px;
        background-size: 40px;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 50%;
        @include RWD(mobile){
            .seu-media-picto{
                height: 40px;
                width: 40px;
                background-size: 20px;
            }
        }
    }
    .seu-media-right{
        flex: 1;
    }
    .seu-media-bottom{
        margin-top: 35px;
        width: 100%;

        @include RWD(mobile){
            margin-top: 20px;
        }
    }
    // Texte
    .seu-media-text{
        .seu-media-title{
            font-family: $site-font;
            font-weight: 700;
            text-transform: uppercase;
            font-size: 1.7rem;
            line-height: 1.58;

            @include RWD(mobile){
                font-size: 1.4rem;
                margin-bottom: 10px;
            }
        }
        li, p{
            font-family: $main-font;
            font-size: 1.5rem;
            font-weight: 400;
            line-height: 1.8;
            margin-bottom: 20px;
            @include RWD(mobile){
                font-size: 1.2rem;
            }
        }
    }
}