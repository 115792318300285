%organism-home-banner{
    height: 720px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    height: calc(100vh - 50px);
    min-height: 720px;
    overflow: hidden;

    @include RWD(mobile){
        height: 500px;
        min-height: unset;
    }

    &:before{
        content: '';
        display: block;
        background: linear-gradient(to bottom, rgba(0,0,0,0.2) 0%,rgba(0,0,0,0.3) 100%);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%; height: 100%;
        z-index: 1;
    }

    & > * {
        z-index: 2;
        position: relative;
    }

    & > .seu-container{
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    h1{
        margin-bottom: 40px;
        transform: translateY(-50px);
        opacity: 0;
        transition: all 2s;

        @include RWD(mobile){
            display: none;
        }
    }
    // Champ de recherche
    .seu-banner-form{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
        opacity: 0;
    }
    .seu-search{
        position: relative;
        max-width: 100%;
        ::-webkit-input-placeholder {
            text-transform: uppercase;
        }
        :-moz-placeholder{ 
            text-transform: uppercase;
        } 
        ::-moz-placeholder { 
            text-transform: uppercase;
        }
        :-ms-input-placeholder {  
            text-transform: uppercase;
        }
        input{
            border: none;
            border-radius: 5px;
            padding: 20px 40px;
            background-color: #FFFFFF;
            color: #7e7e7e;
            width: 520px;
            max-width: 100%;
            font-family: $montserrat;
            font-size: 1.4rem;
            font-weight: 400;
            @include RWD(mobile){
                padding: 15px 40px 15px 15px;
                font-size: 1.2rem;
            }
        }
        button{
            position: absolute;
            top: 50%;
            right: 0;
            width: 100px;
            height: 100%;
            content: '';
            display: block;
            background-size: 20px;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url( svg-bg-uri($svg-loupe, #3a3a3a) );
            transform: translateY(-50%);

            @include RWD(mobile){
                width: 40px;
                background-size: 15px;
            }
        }
    }
    // Liste de liens
    .seu-quicklinks{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        .seu-quicklink{
            margin: 0 5px 10px 5px;
            @include RWD(mobile){
                padding: 5px 10px;
                .seu-btn-text{
                    margin-right: 5px;
                    font-size: 1.2rem;
                }
            }
        }
        
        @for $i from 1 through 15{
            .seu-quicklink{
                &:nth-child(#{$i}){
                    transform: translateY(40px);
                    opacity: 0;
                    transition: all, 0.5s;
                    transition-delay: #{ ( ($i - 1) * 0.1 ) + 0.5}s;
                }
            }
        }
           
    }
    @keyframes bounceScroll{
        0%{
            transform: rotate(90deg) translateX(-50%) translateY(50%) translateX(-10px);
        }
        100%{
            transform: rotate(90deg) translateX(-50%) translateY(50%) translateX(0px);
        }
    }
    // Bouton scroll to
    .seu-scrollTo{
        content: '';
        display: inline-block;
        width: 50px;
        height: 50px;
        background-size: 20px;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url( svg-bg-uri($svg-arrow1, #FFFFFF) ); 
        position: absolute;
        bottom: 0px;
        left: 50%;
        transform: rotate(90deg) translateX(-50%) translateY(50%);
        animation: bounceScroll 0.3s infinite alternate;
    }
    // Animations

    
}