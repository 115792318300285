%organism-wi-link-group{
    background-color: #ffffff;
    padding-bottom: 20px;
    @include RWD(mobile){
        padding: 10px 0;
    }

    &.seu-external{
        .seu-media-title{
            &:after{
                content: '';
                display: inline-block;
                width: 20px;
                height: 20px;
                background-size: 20px;
                background-position: center;
                background-repeat: no-repeat;
                background-image: url( svg-bg-uri($svg-external, $core-color) );
                transform: scale(0.6);
                @include RWD(mobile){
                    transform: scale(0.4);
                }
            }
        }
    }

    .seu-media-picto{
        background-color: #f6f6f6;
        background-image: url( svg-bg-uri($svg-insta, $core-color) );
        @include RWD(mobile){
            height: 25px;
            width: 25px;
            background-size: 15px;
        }
    }
    .seu-media-left{
        @include RWD(mobile){
            width: auto;
            margin-right: 10px;
        }
    }
    .seu-media-right{
        display: flex;
        align-items: center;
        @include RWD(mobile){
            display: block;
        }
    }
    .seu-media-text{
        margin-right: 60px;
        margin-bottom: 10px;
        flex: 1;
        @include RWD(mobile){
            margin-right: 0;
            margin-bottom: 0;
        }
        .seu-media-title{
            color: $core-color;
            line-height: 1.2;
            margin-bottom: 5px;
        }
        p{
            color: $text-basis-color;
        }
        & > *:last-child{
            margin-bottom: 0;
        }
    }
    .seu-link-group-arrow{
        flex-shrink: 0;
        content: '';
        display: inline-block;
        width: 60px;
        height: 60px;
        background-size: 25px;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 50%;
        background-color: $second-color;
        background-image: url( svg-bg-uri($svg-arrow1, $core-color) );
        transition: all 0.5s;
        @include RWD(mobile){
            display: none;
            .seu-media-picto{
                height: 40px;
                width: 40px;
                background-size: 20px;
            }
        }
    }
    &:hover, &:focus{
        text-decoration: none;
        .seu-link-group-arrow{
            background-color: $core-color;
            background-image: url( svg-bg-uri($svg-arrow1, $second-color) );
        }
    }

    // Ratio embed
    .seu-media-ratio{
        height: unset;
        padding-bottom: 56.36%;
        position: relative;
        & > * {
            position: absolute;
            top: 0; left: 0; height: 100%; width: 100%;
        }
    }
}