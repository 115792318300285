%organism-wi-agenda{
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    background-color: #f6f6f6;
    @extend %wi-centered-text;
    // Couleurs Titre
    .seu-section-title{
        .seu-title{
            color: $core-color;
        }
        &:before{
            background-image: url( svg-bg-uri($svg-wave, #dddddd) ); 
        }
    }
    // Contenu
    .seu-wi-content{
        margin-bottom: 40px;

        @include RWD(mobile){
            margin-bottom: 20px;
        }
    }
    .seu-wi-grid{
        @include RWD(desktop){
            @include grid-distribution(4, 20, 'seu-wi-item');
        }                    
        @include RWD(tablet){
            @include grid-distribution(2, 20, 'seu-wi-item');
        }
        @include RWD(mobile){
            @include grid-distribution(1, 0, 'seu-wi-item');
        }
    }
    .seu-wi-item{
        @extend %atom-wi-agenda-vignette;
    }
}