%atom-link-rte{
    text-decoration: underline;
    transition: color, 0.5s;
    color: $core-color;
    font-weight: 600;
        
    &:hover, &:focus{
        color: #2da192;
        text-decoration: underline;
    }

    // &.external-link{
    //     &:after{
    //         content: '*';
    //         vertical-align: super;
    //         font-size: smaller;
    //     }
    // }
}
