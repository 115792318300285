// Banner
#seu-banner{
    &.seu-loaded{
        h1{
            transform: translateY(0);
            opacity: 1;
        }
        .seu-quicklinks .seu-quicklink{
            opacity: 1;
            transform: translateY(0);
        }
        .seu-banner-form{
            animation: bounceInUp 0.4s 1.5s;
            animation-fill-mode: forwards;
        }
    }
} 

// En 1 clic
#seu-quicklinks{
    [data-scroll-animation='grid-delayed']{
       @for $i from 0 through 5{
           .delayed-element-#{$i}{
               opacity: 0;
               transform: scale(0.8);
               transition: all, 0.3s;
               transition-delay: #{($i - 1) * 0.1}s;
           }
       }
       &[data-animation-state='active']{
           .delayed-element{
               opacity: 1;
               transform: scale(1);
           }
       }
    }
}

// Vous Êtes
#seu-profils{    
    &[data-scroll-animation]{
        .seu-text.seu-left{
            transform: translateX(-100px);
            opacity: 0;
        }
        .seu-text.seu-right{
            transform: translateX(100px);
            opacity: 0;
        }
        &[data-animation-state="active"]{
            .seu-text{
                transform: translateX(0);
                opacity: 1;
            }
        }
    }
}