%bone-form-structure{
    $gutter: 30;

    .widget{
        display: block;
        flex-wrap: wrap;
        align-items: flex-start;
        margin-bottom: 35px;

        & + .comment-field{
            margin-top: -35px;
        }

        .title{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 10px;
        }
        .content{
            display: flex;
            flex-direction: column;
            position: relative;

            .field-suffix{
                position: absolute;
                top: 50%;
                right: 10px;
                transform: translateY(-50%);
            }
        }
    }
    

    
    .hint{
        width: 100%;
        // margin-left: calc(17% + #{$gutter / 2}px);
    }

    .buttons.submit{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row-reverse;

        > * {
            margin-left: 20px;
        }

        @include RWD(mobile){
            flex-direction: column;
            align-items: flex-end;

            & > *{
                margin-left: 0;

                & + * {
                    margin-top: 20px
                }
            }
        }
        .previous-button{
            order: 3;
            margin-right: auto;
            margin-left: 0;
            @include RWD(mobile){
                margin: 0;
            }
        }
        .cancel-button{
            order: 2;
            @include RWD(mobile){
                margin: 0;
                margin-bottom: 10px;
            }
        }
        .submit-button{
            order: 1;
            @include RWD(mobile){
                margin: 0;
                margin-bottom: 10px;
            }
        }
    }


    @include RWD(mobile){
        & > .widget{
            display: block;
            .title{
                width: 100%;
                margin-right: 0;
                margin-bottom: 10px;
                display: block;
            }
            .content{
                width: 100%;
                margin-left: 0;
            }
        }
    }
}