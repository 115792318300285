%organism-home-socials{
    padding-bottom: 0;
    background-color: #f6f6f6;
    padding-top: 315px;
    margin-top: -250px;
    @include RWD(tablet){
        padding-top: 270px;
    }
    @include RWD(mobile){
        padding-top: 220px;
    }
    .seu-no-js-links{
        display: none;
    }
}