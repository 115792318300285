%organism-wi-chiffres{
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    background-color: $second-color;
    @extend %wi-centered-text;
    // Couleurs Titre
    .seu-section-title{
        margin-bottom: 0;
        .seu-title{
            color: $core-color;
        }
        &:before{
            background-image: url( svg-bg-uri($svg-wave, #ffffff) ); 
        }
    }
    // Contenu
    .seu-wi-content{
        text-align: center;
        margin-bottom: 40px;

        @include RWD(mobile){
            margin-bottom: 20px;
        }
    }
    .seu-wi-chiffres-list{
        position: relative;
        @include RWD(desktop){
            @include grid-distribution(4, 50, 'seu-wi-chiffres-item');            
        }
        @include RWD(tablet_only){
            @include grid-distribution(2, 30, 'seu-wi-chiffres-item');            
        }
        margin-bottom: 70px;
        @include RWD(mobile){
            margin-bottom: 40px;
        }
    }
    .seu-wi-chiffres-item{
        &:before{
            content: '';
            display: block;
            height: 100%; width: 1px;
            position: absolute;
            top: 0;
            left: 0;
            background-color: #5bded0;
            @include RWD(mobile){
                display: none;
            }
        }
        &:nth-child(2){
            &:before{
                left: 25%;
                @include RWD(tablet){
                    display: none;
                }
            }
        }
        &:nth-child(3){
            &:before{
                left: 50%;
                @include RWD(tablet){
                    display: none;
                }
            }
        }
        &:nth-child(4){
            &:before{
                left: 75%;
                @include RWD(tablet){
                    display: none;
                }
            }
        }
        &:first-child{
            &:before{
                display: none;
            }
        }
        @include RWD(tablet_only){
            margin-bottom: 20px;
            &:nth-child(3),
            &:nth-child(3){
                margin-bottom: 0;
            }
        }
        @include RWD(mobile){
            margin-bottom: 20px;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
    .seu-wi-text{
        .seu-wi-title{
            color: $core-color;
            text-align: center;
        }
        p{
            color: #FFFFFF;
        }
    }
    .seu-subtitle{
        color: $core-color;
        font-family: $site-font;
        font-weight: 400;
        font-size: 1.4rem;
        text-transform: uppercase;
        color: $core-color;
        text-align: center;
        display: block;
    }
}