%organism-home-profil{
    height: 375px;
    position: relative;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
    @include RWD(tablet){
        height: auto;
    }
    .seu-pictures{
        display: flex;
        height: 100%;
        flex-wrap: wrap;
        .seu-picture{
            width: 50%;
            background-size: cover;
            position: relative;
            @include RWD(tablet){
                width: 100%;
                height: 350px;
            }
            @include RWD(mobile){
                height: 200px;
            }
            &:after{
                content: '';
                display: block;
                position: absolute;
                top: 0; left: 0; 
                width: 100%;
                height: 100%;
                z-index: 1;
                opacity: 0.85;
            }
        }
        .seu-left{
            &:after{
                background-color: $second-color;
            }
        }
        .seu-right{
            &:after{
                background-color: $third-color;
            }
        }
    }
    .seu-container{
        display: flex;
        flex-wrap: wrap;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 100%;
        z-index: 2;

        .seu-text{
            width: 50%;
            padding: 70px 35px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-start;
            transition: all 0.5s ease-in-out;


            @include RWD(tablet){
                width: 100%;
                height: 350px;
            }
            @include RWD(mobile){
                padding:  0;
                height: 200px;
                justify-content: center;
            }
            .seu-suptitle{
                font-weight: 400;
                font-family: $montserrat;
                font-size: 3.0rem;
                text-transform: uppercase; 
                line-height: 0.7;   
                margin-bottom: 8px;
                color: $core-color;
                @include RWD(mobile){
                    font-size: 1.8rem;
                }
            } 
            .seu-title{
                font-family: $montserrat;
                font-weight: 700;
                font-size: 5.0rem;
                text-transform: uppercase;
                line-height: 0.8;
                color: $core-color;
                margin-bottom: 20px;
                @include RWD(mobile){
                    font-size: 3.0rem;
                }
            }
        }

        .seu-left{

        }
        .seu-right{

        }
    }
}