%organism-wi-duo{
    @extend %wi-lefted-text;
    flex: 1;
    max-width: 600px;
    position: relative;
    @include RWD(tablet){
        max-width: unset;
    }
    @include RWD(mobile){
        width: 100%;
        max-width: 100%;
    }
    &:nth-child(1){
        .seu-container{
            padding-right: 60px;
            @include RWD(tablet){
                padding-right: 40px;
            }
            @include RWD(mobile){
                padding-right: 20px;
            }
        }
        &:before{
            background-color: $second-color;
        }
        @include RWD(tablet){
            background-color: $second-color;         
        }
    }
    &:nth-child(2){
        .seu-container{
            @include RWD(desktop){
                padding-left: 60px;                    
            }
            @include RWD(tablet_only){
                padding-left: 40px;
            }
            @include RWD(mobile){
                padding-left: 20px;
            }
        }
        &:before{
            left: 0 !important;
            z-index: 2 !important;
            background-color: #5ddfd1;   
        }
        @include RWD(tablet){
            background-color: #5ddfd1;            
        }
    }
    &:before{
        content: '';
        display: block;
        position: absolute;
        z-index: 1;
        left: -999999px;
        right: -999999px;
        top: 0;
        bottom: 0;
        @include RWD(tablet){
            display: none;
        }
    }
    .seu-container{
        max-width: 100%;
        z-index: 3;
        position: relative;
    }
    // Titre de widget
    .seu-section-title{
        .seu-title{
            color: $core-color;
        }
        &:before{
            background-image: url( svg-bg-uri($svg-wave, #FFFFFF) ); 
        }
    }
    // Contenu
    .seu-wi-content{
        @include RWD(mobile){
            margin-bottom: 20px;
        }
    }
    .seu-wi-content{
        .seu-wi-title{
            color: $core-color;
        }
        p{
            color: $core-color;
        }
    }
}