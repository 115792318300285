%organism-home-quicklinks{
    .seu-section-title{
        &:before{
            background-image: url( svg-bg-uri($svg-wave, #505d6d) );
        }
        .seu-title{
            color: #FFFFFF;
        }
    }
    .seu-quicklinks-list{
        display: flex;
        justify-content: space-around;
        margin-bottom: 30px;
        @include RWD(mobile){
            flex-wrap: wrap;
        }

        .seu-quicklink{
            max-width: 115px;
            width: 20%;
            text-align: center;
            &:hover, &:focus{
                text-decoration: none;
            }
            @include RWD(mobile){
                width: 33%;
                margin-bottom: 20px;
                &:nth-child(1), &:nth-child(2){
                    // width: 50%;
                }
            }

            &:hover, &:focus{
                .seu-picto{
                    &:before{
                        opacity: 0.1;
                        transform: translate(-50%, -50%) scale(1.1);
                    }
                }
                .seu-title{
                    transform: translateY(0px);
                }
            }
        }

        .seu-picto{
            content: '';
            display: flex;
            align-items: center;
            justify-content: center;
            width: 90px;
            height: 90px;
            border-radius: 50%;
            margin: 0 auto;
            position: relative;
            &:before{
                position: absolute;
                top: 50%; left: 50%;
                content: '';
                background-color: #FFFFFF;
                height: 100%;
                width: 100%;
                border-radius: 50%;
                z-index: -1;
                transform: translate(-50%, -50%) scale(1.4);
                opacity: 0;
                transition: all, 0.3s;
            }
            img{
                width: 60px;
                height: 60px;
            }
            @include RWD(mobile){
                width: 80px;
                height: 80px;
            }
        }
        .seu-title{
            font-family: $montserrat;
            font-weight: 700;
            font-size: 1.7rem;
            color: #FFFFFF;
            margin-top: 15px;
            transform: translateY(-15px);
            transition: all, 0.3s;
            text-transform: uppercase;

            @include RWD(mobile){
                font-size: 1.4rem;
            }
        }
    }
}