%molecule-debug-master{
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 300px;
    max-width: 50vw;
    height: 500px;
    max-height: 80vh;
    z-index: 999999999999999;
    background-color: #2D2D2D;
    overflow: auto;
    padding: 20px;
    display: none;

    a{
        color: #FFFFFF;
        font-size: 12px;
        text-decoration: none;
    }
    .close{
        position: absolute;
        right: 10px;
        top: 10px;
        content: '';
        display: inline-block;
        width: 20px;
        height: 20px;
        background-size: 20px;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url( svg-bg-uri($svg-close, #FFFFFF) );
        font-size: 0;
    }
}