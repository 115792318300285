%text-form-input{
    font-family: $main-font;
    color: #505050;
    font-size: 1.8rem;
    font-weight: 400;
}
%text-form-label{
    font-weight: 600;
    font-family: $main-font;
    color: #505050;
    font-size: 1.8rem;
}