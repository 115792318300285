%molecule-pagination{    
    width: 100%;
	text-align: center;
	display: flex;
	justify-content: flex-start;
	align-items: center;

	.seu-pagin-item{
		display: inline-block;
		color: inherit;
		margin-left: 15px;

		&:before{
			display: none !important;
		}

		a{
			position: relative; 
			z-index: 2;
		}

		&.seu-pagin-prev{
			margin-left: 0;
			margin-right: auto;
		}

		&.seu-disabled{
			pointer-events: none;
		}
		&.seu-is-active{
			pointer-events: none;
		}
		&.seu-pagin-next{
			margin-left: auto;
			margin-right: 0;
		} 
		&.seu-pagin-first{
			margin-left: 0;
		} 

		/**
			Pagination "chiffres"
		*/
		&:not(.seu-pagin-next):not(.seu-pagin-prev):not(.seu-pagin-first):not(.seu-pagin-last){
			& > a, & > div{
				text-decoration: none;
			}
			.seu-btn-text{
				font-family: $site-font;
				font-size: 1.5rem;
				font-weight: 600;
				padding: 3px;
			} 
			&.seu-is-active{
				text-decoration: underline;
			}
			a:hover, a:focus{
				.seu-btn-text{
					color: #FFFFFF;
				}
				background-color: $core-color;
			}
		}
		/**
			Pagination "Boutons"
		*/
		// &.seu-pagin-next,
		// &.seu-pagin-prev,
		// &.seu-pagin-first,
		// &.seu-pagin-last{
		// 	&:not(.seu-is-active):not(.seu-disabled){			
		// 		& > div, & > a{
		// 			@extend %atom-btn-int--core;
		// 			padding: 0 15px;
		// 			text-decoration: none;
		// 		}
		// 	}
		// 	&.seu-is-active, &.seu-disabled{
		// 		& > div, & > a{
		// 			@extend %atom-btn-int--white; 
		// 			padding: 0 15px;
		// 			text-decoration: none;
		// 		}
		// 	} 
		// }
	}
	@include RWD(tablet){
		.seu-pagin-first,
		.seu-pagin-last{
			display: none !important;
		}
	}
	@include RWD(mobile){
		.seu-pagin-item:not(.seu-pagin-next):not(.seu-pagin-prev){
			display: none;
		}
		.seu-pagin-next{
			margin-left: auto;
		}
		.seu-pagin-next, .seu-pagin-prev{
			.seu-btn-text{
				font-size: 0 !important;
			}
		}
		.seu-pagin-prev .seu-btn-text:before{
			content:'Prec'; 
			font-size: 16px;
			font-size: 1.6rem;
		}
		.seu-pagin-next .seu-btn-text:before{
			content:'Suiv';
			font-size: 16px;
			font-size: 1.6rem;
		}
	}
}