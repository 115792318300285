%molecule-header-search{
    display: none;
    background-color: #f6f6f6;
    height: 72px;

    .seu-search{
        @extend %container;
        display: flex;
        align-items: center;
        height: 100%;
        ::-webkit-input-placeholder {
            text-transform: uppercase;
        }
        :-moz-placeholder{ 
            text-transform: uppercase;
        } 
        ::-moz-placeholder { 
            text-transform: uppercase;
        }
        :-ms-input-placeholder {  
            text-transform: uppercase;
        }
        input{
            border: none;
            border-radius: 5px;
            padding: 20px;
            background-color: transparent;
            color: #7e7e7e;
            width: 520px;
            max-width: 100%;
            font-family: $montserrat;
            font-size: 1.4rem;
            font-weight: 400;
            flex: 1;
            @include RWD(mobile){
                padding: 15px 40px 15px 15px;
                font-size: 1.2rem;
            }
        }

        [type="submit"]{
            content: '';
            display: inline-block;
            width: 20px;
            height: 20px;
            background-size: 20px;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url( svg-bg-uri($svg-loupe, #7d7d7d) );
            @include RWD(mobile){
                width: 35px;
            }
        }
        .seu-fill{
            flex: 1;
        }
        .seu-search-close{
            content: '';
            display: inline-block;
            width: 20px;
            height: 20px;
            background-size: 20px;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url( svg-bg-uri($svg-cross, #7d7d7d) );
        }
    }
}