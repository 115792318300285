%organism-home-territory{
    
    .seu-links{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-bottom: 40px;
        @include RWD(mobile){
            flex-direction: column;
            align-items: center;
        }

        & > a {
            margin: 0 20px 20px 20px;
            @include RWD(mobile){
                margin: 0;
                margin-bottom: 5px;
                .seu-btn-text{
                    font-size: 1.2rem;
                }
            }
            &:first-child{
                margin-left: 70px;
                @media(max-width: 1100px){
                    margin-left: 20px;
                }
            }
            &:nth-child(3){
                margin-right: 70px;
                @media(max-width: 1100px){
                    margin-right: 20px;
                }
            }
        }
    }

    .seu-territories{
        display: flex;
        flex-wrap: wrap;
    }
    .seu-territory{
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
        width: 50%;
        height: 320px;
        position: relative;
        display: flex;
        align-items: center;
        background-size: cover;
        background-repeat: no-repeat;
        &.seu-left{
            // transform: translateY(-20px);
        }
        &.seu-right{
            // transform: translateY(20px);
        }
        @include RWD(tablet){
            width: 100%;
        }
        @include RWD(mobile){
            margin-bottom: 20px;
            height: 250px;
        }
        &:before{
            background: linear-gradient(to right, rgba(0,0,0,0.4) 0%,rgba(0,0,0,0.36) 15%,rgba(0,0,0,0.08) 74%,rgba(0,0,0,0) 100%);
            content: '';
            display: block;
            position: absolute;
            top: 0; left: 0;
            width: 100%; height: 100%;
            z-index: 1;
        }
        & > * {
            z-index: 2;
            position: relative;
        }
        
        .seu-text{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-end;
            height: 100%;
            padding: 0 10px 70px 45px;
            flex: 1;
            @include RWD(mobile){
                padding: 20px;
                width: 100%;
                justify-content: center;
            }

            .seu-suptitle{
                font-weight: 400;
                font-family: $montserrat;
                font-size: 2.0rem; 
                text-transform: uppercase; 
                line-height: 0.7;   
                margin-bottom: 8px;
                color: #FFFFFF;
                @include RWD(mobile){
                    font-size: 1.5rem;
                }
            } 
            .seu-title{
                font-family: $montserrat;
                font-weight: 700;
                font-size: 4.0rem;
                text-transform: uppercase;
                line-height: 0.8;
                color: #FFFFFF;
                margin-bottom: 20px;
                @include RWD(mobile){
                    font-size: 2.5rem;
                }
            }
            form{
                align-self: stretch;
                width: 320px;
                max-width: 100%;
            }
            .customSelectContain{
                @extend %atom-customSelect--grey; 

                @include RWD(mobile){
                    .customSelectInner{
                        font-size: 1.2rem !important;
                    }
                }
            }

        }

        .seu-map{
            padding: 10px;
            @include RWD(mobile){
                display: none;
            }
            img{
                max-height: 100%;
            }
        }
    }
}