%molecule-form-progressbar{
    border-top: solid 1px #F6F6F6;
    padding-top: 30px;
    margin-top: 30px;
    #tracking-code{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 25px;
        &:before{
            content: '';
            display: inline-block;
            width: 70px;
            height: 70px;
            background-size: 40px;
            background-position: center;
            background-repeat: no-repeat;
            border-radius: 50%;
            background-color: #F6F6F6;
            background-image: url( svg-bg-uri($svg-ticket, $core-color) );
            @include RWD(mobile){
                .seu-media-picto{
                    height: 40px;
                    width: 40px;
                    background-size: 20px;
                }
            }
        }
        h3{
            font-size: 1.8rem;
            font-family: $main-font;
            font-weight: 600;
            color: #505050;
        }
        a{
            font-family: $site-font;
            font-weight: 700;
            color: $core-color;
            font-size: 2.5rem;
            text-transform: uppercase;
            line-height: 1;
        }
    }
    #steps{
        margin-bottom: 30px;
        & > h2{
            display: none;
        }
        ol{
            display: flex;
            justify-content: space-between;
            max-width: 800px;
            margin: 0 auto;
            position: relative;

            &:before{
                content: '';
                background-color: $second-color;
                position: absolute;
                top: 35px;
                left: 30px;
                width: calc(100% - 60px);
                -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
                height: 4px;
                z-index: -1;
                @include RWD(mobile){
                    top: 14px;
                }
            }

            li{
                &.first{
                    &:before{
                        content: '';
                        background-color: #ffffff;
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 100%;
                        z-index: -1;
                        width: 60px;
                        @include RWD(mobile){
                            display: none;
                        }
                    }
                }
                &.last{
                    &:after{
                        content: '';
                        background-color: #ffffff;
                        position: absolute;
                        top: 0;
                        right: 0;
                        height: 100%;
                        z-index: -1;
                        width: 60px;
                        @include RWD(mobile){
                            display: none;
                        }
                    }
                }
                display: block;
                text-align: center;
                width: 160px;
                @include RWD(mobile){
                    width: unset;
                }
                .marker{
                    z-index: 10;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    width: 70px;
                    height: 70px;
                    color: #FFFFFF;
                    font-family: $site-font;
                    font-weight: 500;
                    font-size: 3.0rem;
                    margin-bottom: 10px;
                    position: relative;
                    &:before{
                        content: '';
                        display: block;
                        height: 100%; width: 100%;
                        left: 0; top: -2px;
                        position: absolute;
                        z-index: -1;
                        border-radius: 50%;
                    }
                    @include RWD(mobile){
                        height: 30px;
                        width: 30px;
                        font-size: 1.4rem;
                    }
                }
                .label{
                    display: inline-block;
                    color: $core-color;
                    font-size: 1.7rem;
                    font-weight: 700;
                    display: block;
                    hyphens: auto;
                    max-width: 100%;
                    white-space: unset;
                    padding: 0;
                    text-transform: uppercase;
                    @include RWD(mobile){
                        display: none;
                    }
                }

                //  Couleurs des étapes
                &.step-before{
                    .marker:before{
                        background-color: $second-color;
                    }
                }
                &.current{
                    .marker:before{
                        background-color: $second-color;
                    }
                }
                &.step-after{
                    .marker:before{
                        background-color: $core-color;
                    }
                }
            }
        }
    }
}