.seu-not-front .view-list,
.seu-not-front.page-search-site{
    // Résultats 
    .view-header{
        margin-bottom: 20px;
    }
    
    // Date separator spé Agenda
    .view-date-separator{
        @extend %atom-h2-rte;
        margin-bottom: 20px;
    }
    
    // Pagination
    .pagination{
        margin-bottom: 40px;
    }

    // Apparence RTE Drupal 
    .view-recherche{
        #main-content ul {
            @extend %atom-ul-rte;
        }
    }
}