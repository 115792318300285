.seu-not-front{
    @extend %text-basis-rte;
    padding-top: 105px;
    
    @include RWD(tablet){
        padding-top: 45px;
    }
    h1{
        @extend %atom-main-title;
        &:before{ 
            content: '';
            display: block;
            height: 15px;
            background-size: 50px; 
            background-position: center;
            background-repeat: no-repeat; 
            background-image: url( svg-bg-uri($svg-wave, #dddddd) ); 
            margin-bottom: 25px;

            @include RWD(mobile){
                margin-bottom: 10px;
                background-size: 35px;
            }
        }
        margin-bottom: 50px;
        @include RWD(mobile){
            margin-bottom: 30px;
        }
    }
    .hat{
        margin-bottom: 50px;
        @include RWD(mobile){
            margin-bottom: 30px;
        }
    }
    .region-post-header{
        .region-nav-tools{
            position: relative;
            z-index: 10;
            padding-top: 5px;
            padding-bottom: 5px;
            @extend %container;
            display: flex;
            align-items: center;     
            margin-bottom: 50px;
            margin-top: 40px;
        }
        .block-container-breadcrumb{
            margin-right: auto;
            padding-left: 0;
        }
        &.has-banner{
            .region-nav-tools{
                margin-top: 0;
                transform: translateY(-50%);
                margin-bottom: 5px;
            }
            .block-container-breadcrumb{
                background-color: #f6f6f6;
                box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
                padding: 20px 30px;
                @include RWD(mobile){
                    padding: 10px 15px;
                }
            }
        }
    }

    // Publié le d'une actu
    .actu-published{
        text-align: right;
        font-style: italic;
        color: $core-color-access;
        font-size: 12px;
        font-size: 1.2rem;
    }
}