%molecule-form-components{
    ::-webkit-input-placeholder {
        font-family: $site-font;
        font-size: 1.4rem;
        text-transform: uppercase;
        color: #7e7e7e;
    }
    :-moz-placeholder{ 
        font-family: $site-font;
        font-size: 1.4rem;
        text-transform: uppercase;
        color: #7e7e7e;
    } 
    ::-moz-placeholder { 
        font-family: $site-font;
        font-size: 1.4rem;
        text-transform: uppercase;
        color: #7e7e7e;
    }
    :-ms-input-placeholder {  
        font-family: $site-font;
        font-size: 1.4rem;
        text-transform: uppercase;
        color: #7e7e7e;
    }

    .text-info{
        margin-bottom: $margin-p;
    }
    
    input:not([type="radio"]):not([type="checkbox"]):not([type="file"]):not(.webform-calendar){ 
        @extend %atom-form-input;

        &[type="file"]{
            border: none;
            padding: 0; 
            margin-right: 20px;
        }
        &.webform-calendar{
            width: auto;
        } 
    } 
    input[type="file"]{
        display: flex;
        align-items: center;
    }
    label:not(.option){
         @extend %text-form-label;
         position: relative;

         .required{
             color: $error;
         }
     }
    .RadiobuttonsWidget{
        input[type="radio"]{
            @extend %atom-form-radio;
        }
        .content label + label{
            margin-top: 5px;
        }
    }
    .CheckboxesWidget{
        input[type="checkbox"]{
            @extend %atom-form-checkbox;
        }
        .content li + li{
            margin-top: 5px;
        }
    }
    .hint, .comment-field{
        font-size: 1.6rem;
        font-family: $main-font;
        font-style: italic;
    }
    .content label{
        font-weight: 400;
        text-transform: none;
    }
    .CheckboxWidget.widget{
        .content{
            display: flex;
            flex-direction: row;
            align-items: center;
            input{
                margin-top: 0;
                margin-right: 15px;
            }
        }
    }
    p{
        margin-bottom: 20px;
    }
    //  Textarea
    textarea{
        @extend %atom-form-input;
        @extend %text-form-input;
        padding: 15px;
        height: auto; 
        display: block;
        resize: vertical;
    }
    //  SELECTS
     .customSelectContain{
         @extend %atom-customSelect--grey;
         height: 50px;
         &.focused{
             .customSelect{
                 border-color: $core-color;
             }
         }
         .customSelect{
             background-color: #F6F6F6;
             border: solid 2px transparent;
         }
     }   

    // Date picker
    #ui-datepicker-div{
        @extend %atom-datepicker;
    }  

     
 
    //  FILES
     .form-managed-file{
         display: flex;
         align-items: center;
         @extend %atom-form-file;

         button{
             height: 35px;
         }
     }
 
    //  Indications sous le field
     .form-infos{
         @extend %text-form-input;
         margin-top: 5px;
         font-size: small;
     }

    //  Grille de notation
     .type-webform-grid{
        & > .form-field{
            overflow: auto;
        }
     } 
    .webform-grid{
        @extend %molecule-table;
        @extend %molecule-form-table;
        width: 100%;
        border-spacing: 0;
        @include RWD(tablet){
            width: auto;
            max-width: 100%;
        }

    }

    .form-submit, [type="submit"]{
        @extend %atom-btn-int--core;
    } 
}